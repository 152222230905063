const isProd = process.env.ENV === 'PROD';

export default {
  appVersion: process.env.APP_VERSION || '0.0.0',
  commitHash: process.env.COMMIT_HASH || '0000000',
  deployEnv: process.env.DEPLOY_ENV || 'local',
  apiUrl: process.env.API_URL || 'http://localhost:8080/',
  env: process.env.ENV || 'DEV',
  nodeEnv: process.env.NODE_ENV || 'development',
  isTestMode: process.env.NODE_ENV === 'test',
  isProd,
  isDev: !['PROD', 'STABLE'].includes(process.env.ENV || ''),
  isStable: (process.env.ENV || '') === 'STABLE',
  isWorker: process.env.WORKER === 'true',
  maintenanceMode: process.env.MAINTENANCE_MODE === 'true',
  maintenanceModeEta: process.env.MAINTENANCE_MODE_ETA || '',
  port: process.env.PORT || 80,
  assetsBaseUrl:
    process.env.ASSETS_BASE_URL ||
    'https://turbotenant-production.s3.amazonaws.com/uploads',
  rcrPromoBannerUrl:
    process.env.RCR_PROMO_BANNER_URL ||
    'https://gifcdn.com/32c1k70r34b9k60oj6cpn.gif',
  pixelTrackingAllowedHosts: (process.env.PIXEL_TRACKING_ALLOWED_HOSTS &&
    process.env.PIXEL_TRACKING_ALLOWED_HOSTS.split(/, */g)) || [
    '.turbotenant.com',
    'localhost',
    '127.0.0.1',
    'fbapp',
    'tt-dev-storybook.s3.amazonaws.com',
    'www.bing.com',
  ],
  introspection: !isProd && process.env.INTROSPECTION !== 'false',
  jwtPrivateKey: process.env.JWT_PRIVATE_KEY,
  jwtExpirationTime: process.env.JWT_EXPIRATION_TIME,
  sso: {
    forum: {
      jwtKey:
        process.env.JWT_KEY_WORDPRESS_FORUM ||
        '90aaa56f65dcdfa583663d6fca48211adc06357e54ce7efa7c936b9a4e0f753b',
    },
  },
  expirationTime: (role) => {
    const exp = process.env.JWT_EXPIRATION_TIME;
    switch (role) {
      case 'OWNER':
        return process.env.JWT_EXPIRATION_TIME_OWNER || exp;
      case 'RENTER':
        return process.env.JWT_EXPIRATION_TIME_RENTER || exp;
      case 'ADMIN':
        return process.env.JWT_EXPIRATION_TIME_ADMIN || exp;
      case 'LANDLORD_REFERENCE':
        return process.env.JWT_EXPIRATION_TIME_LANDLORD_REF || exp;
      case 'ADDITIONAL_SIGNER':
        return process.env.JWT_EXPIRATION_TIME_ADDITIONAL_SIGNER || exp;
      case 'EMAIL_PREFERENCES':
        return (
          process.env.JWT_EXPIRATION_TIME_EMAIL_PREFERENCES || '15768000000' // 6 months
        );
      case 'LEADS_QUESTIONNAIRE':
        return process.env.JWT_EXPIRATION_TIME_LEADS_QUESTIONNAIRE || exp;
      case 'UPLOAD_RR_DOCUMENTS':
        return process.env.JWT_EXPIRATION_UPLOAD_RR_DOCUMENTS || '2592000000'; // 30 days
      case 'SET_RENTER_PASSWORD':
        return process.env.SET_RENTER_PASSWORD || '2592000000'; // 30 days
      case 'TRUSTED_DEVICE_VERIFICATION':
        return process.env.TRUSTED_DEVICE_VERIFICATION || '1800000'; // 30 min
      case 'UP_VERIF_DOCS_ANOTHER_DEVICE':
        return process.env.UP_VERIF_DOCS_ANOTHER_DEVICE || '86400000'; //24hs
      case 'SCREENING_REPORT_LINK':
        return process.env.SCREENING_REPORT_LINK || '900000'; // 15 minutes
      case 'LEASE_AUDIT_GUEST':
        return process.env.LEASE_AUDIT || '2592000000'; // 30 days
      case 'S_TAX_FORM_VIEWER':
        return process.env.S_TAX_FORM_VIEWER || '21600000'; // 6 hours
      default:
        return exp;
    }
  },
  renterAppUrl: process.env.RENTER_APP_URL || process.env.FRONTEND_URL || false,
  ownerAppUrl: process.env.OWNER_APP_URL,
  branchIOBaseUrl: process.env.BRANCH_IO_BASE_URL || process.env.OWNER_APP_URL,
  branchIOApiUrl: process.env.BRANCH_IO_API_URL || '',
  branchIOApiKey: process.env.BRANCH_IO_API_KEY || '',
  branchIOApiKeyRenter: process.env.BRANCH_IO_API_KEY_RENTER || '',
  sequelizeLogging: process.env.SEQUELIZE_LOGGING !== 'false',
  shortLinks: process.env.SHORT_LINKS !== 'false',
  shortLinksKey: process.env.SHORT_LINKS_KEY || false,
  activityUpdateInterval: Number(process.env.ACTIVITY_UPDATE_INTERVAL || 300), // 5 minutes default interval
  activityBatchUpdateInterval: Number(
    process.env.ACTIVITY_BATCH_UPDATE_INTERVAL || 30,
  ), // 30 seconds default interval
  skipTrustedDeviceCheckGlobalToken:
    process.env.SKIP_TRUSTED_DEVICE_CHECK_GLOBAL_TOKEN ||
    'z4i3ai4ij9flqjzvlecm9te456bqza',
  shortLink: (strings, ...values) =>
    `https://turbo.rent/s/${
      isProd ? '' : `${process.env.ENV || 'DEV'}-`
    }${strings.map((s, idx) => `${s}${values[idx] || ''}`).join('')}`,
  social: {
    googleClientId: {
      web: process.env.GOOGLE_CLIENT_ID,
      ios: process.env.GOOGLE_CLIENT_ID_IOS || false,
      android: process.env.GOOGLE_CLIENT_ID_ANDROID || false,
      iosDEV: process.env.GOOGLE_CLIENT_ID_IOS_DEV || false,
      androidDEV: process.env.GOOGLE_CLIENT_ID_ANDROID_DEV || false,
      iosQA: process.env.GOOGLE_CLIENT_ID_IOS_QA || false,
      androidQA: process.env.GOOGLE_CLIENT_ID_ANDROID_QA || false,
      iosSTAGING: process.env.GOOGLE_CLIENT_ID_IOS_STAGING || false,
      androidSTAGING: process.env.GOOGLE_CLIENT_ID_ANDROID_STAGING || false,
      expo: process.env.GOOGLE_CLIENT_ID_EXPO || false,
    },
    googleRestApi:
      process.env.GOOGLE_REST_API || 'https://www.google-analytics.com',
    googleTrackingId: process.env.GOOGLE_TRACKING_ID || false,
    gtagTrackingId: process.env.GTAG_TRACKING_ID || 'UA-64324164-13',
    serviceEmail: process.env.GA_SERVICE_EMAIL || false,
    serviceKey:
      decodeURIComponent(
        Buffer.from(process.env.GA_SERVICE_KEY || '').toString('utf8'),
      ) || false,
    viewId: process.env.GA_VIEW_ID || '234890699',
  },
  intercomIDVSecret: process.env.INTERCOM_IDV_SECRET || false,
  cache: {
    host: process.env.CACHING_HOST || '',
    port: process.env.CACHING_PORT || 6379,
    enabled:
      process.env.NODE_ENV !== 'test' &&
      process.env.CACHING_ENABLED !== 'false',
    ttl: process.env.CACHING_TTL ? Number(process.env.CACHING_TTL) : 259200, // Default TTL is 3 days
    timeout: process.env.CACHING_TIMEOUT
      ? Number(process.env.CACHING_TIMEOUT)
      : 150,
    renter_enabled:
      process.env.NODE_ENV !== 'test' &&
      process.env.CACHING_RENTERS_PORTAL_ENABLED === 'true',
    responseCache: process.env.CACHE_RESPONSE_CACHE !== 'false',
    cachedOperations:
      (process.env.CACHE_CACHED_OPERATIONS &&
        process.env.CACHE_CACHED_OPERATIONS.split(/, */g)) ||
      [], // CACHED OPERATIONS is only set if we're supposed to cache only a subset of operations
    cacheLengthLimit: Number(process.env.CACHE_LENGTH_LIMIT || 0),
    excludedCachedOperations: [
      'taxForms',
      ...((process.env.CACHE_EXCLUDED_CACHED_OPERATIONS &&
        process.env.CACHE_EXCLUDED_CACHED_OPERATIONS.split(/, */g)) ||
        []),
    ],
    extendGQLCacheExpiration:
      process.env.CACHING_EXTEND_GQL_CACHE_EXPIRATION !== 'false',
    groupCacheEnabled: process.env.CACHING_GROUP_CACHE_ENABLED !== 'false',
  },
  persistedQueries: process.env.PERSISTED_QUERIES !== 'false',
  ipStackApiKey: process.env.IPSTACK_API_KEY || false,
  csvEmail: process.env.CSV_EMAIL || '',
  services: {
    ipLogging: {
      useRedis: process.env.IP_LOGGING_USE_REDIS !== 'false',
    },
  },
  featureFlags:
    (
      (process.env.FEATURE_FLAGS && process.env.FEATURE_FLAGS.split(/, */g)) ||
      []
    )
      .concat([
        'IS_BROKER',
        'DISABLE_TRUSTED_DEVICE',
        'DISABLE_CHECK_INS',
        'DISABLE_FAST_PAYOUTS',
        'ENHANCED_APPLICATION_FLOW',
        'SHOW_TOOLBOX',
        'CALL_FORWARDING',
        'MESSAGING_V2',
        'CUSTOM_PRESCREENER_QUESTIONS',
        'PHONE_MASKING',
        'FORCE_MOBILE_UPDATES',
        ...(isProd ? [] : ['CRAIGSLIST_POSTING']),
      ])
      .filter(
        (feature, pos, featureFlags) => featureFlags.indexOf(feature) === pos,
      ) || [],
  featuresEnabled:
    (process.env.FEATURES_ENABLED &&
      process.env.FEATURES_ENABLED.split(/, */g)) ||
    [],
  leads: {
    mailparserInbox: process.env.MAILPARSER_INBOX || '',
    allowedSources: [
      'Zumper',
      'Show Me The Rent',
      'Homes.com',
      'Apartments.com',
      'Realtor.com',
      'Apartment List',
      'Walk Score',
      'Zillow Rental Network',
      'Hunt',
      'RentJungle.com',
      'FRBO',
      'CampusCribz.com',
      'EveryRent',
      'RadPad',
      'RentPath',
      'Craigslist',
      'Uloop',
      'CollegeRentals',
      'Dwellsy',
    ],
  },
  crypto: {
    fileUploadKey: process.env.FILE_UPLOAD_KEY,
    fileUploadSalt: process.env.FILE_UPLOAD_SALT,
    dbEncryptionKey: process.env.DB_ENCRYPTION_KEY,
    secretKeyBase: process.env.SECRET_KEY_BASE,
  },
  s3: {
    bucketName: process.env.S3_BUCKET_NAME,
    accessKey: process.env.S3_ACCESS_KEY_ID,
    secret: process.env.S3_SECRET_ACCESS_KEY,
    region: process.env.S3_DEFAULT_REGION || 'us-east-1',
  },
  db: {
    // sequelize-cli uses the config directly from here
    databaseUrl: process.env.DB_URL,
    ssl: process.env.DB_SSL === 'true' && process.env.NODE_ENV !== 'test',
    dialect: 'postgres',
    dialectOptions:
      process.env.DB_SSL === 'true' && process.env.NODE_ENV !== 'test'
        ? {
            ssl: {
              required: true,
              rejectUnauthorized: false,
            },
          }
        : {},
    username:
      process.env.DB_URL &&
      (process.env.DB_URL.match(/^postgres:\/\/[a-zA-Z\d_]+[:|@]/g) || [
        '',
      ])[0].slice(11, -1),
    password:
      process.env.DB_URL &&
      (process.env.DB_URL.match(/:[^(@|:)]+@/g) || [''])[0].slice(1, -1),
    database:
      process.env.DB_URL &&
      (process.env.DB_URL.match(/\/[^/]+$/g) || [''])[0].slice(1),
    host:
      process.env.DB_URL &&
      (process.env.DB_URL.match(/@[^:]+:/g) || [''])[0].slice(1, -1),
    pool: process.env.DB_POOL || '',
  },
  devAllowedDomains: ['turbotenant.com', 'tt.com', 'redfin.com'].concat(
    (process.env.DEV_ALLOWED_DOMAINS || '').split(/, */g) || [],
  ),
  mailer: {
    enabled:
      process.env.EMAILS_ENABLE === 'true' && process.env.NODE_ENV !== 'test',
    mailService: 'sendgrid',
    stagingEmail: process.env.STAGING_EMAIL || false,
    skipTestEmailDomains: process.env.SKIP_TEST_EMAIL_DOMAINS
      ? process.env.SKIP_TEST_EMAIL_DOMAINS.split(/, */g) || []
      : [],
    sendTestEmails: isProd || process.env.SEND_TEST_EMAILS === 'true',
    sendgrid: {
      apiKey: process.env.SENDGRID_API_KEY || '',
    },
    emails: {
      founder: 'sarnens@turbotenant.com',
      support: 'support@turbotenant.com',
      security:
        process.env.FAKE_SECURITY_EMAIL && process.env.ENV !== 'PROD'
          ? process.env.FAKE_SECURITY_EMAIL
          : 'security@turbotenant.com',
      noReply: 'no-reply@turbotenant.com',
    },
  },
  stripe: {
    azibo: {
      apiKey:
        process.env.AZIBO_STRIPE_KEY ||
        'sk_test_uJDXFPPPVmFjiqRBjdMIIsAR00MVeCywKQ',
      publicKey:
        process.env.AZIBO_STRIPE_PUBLIC_KEY ||
        'pk_test_AILT5K8l7SHZTv37fAiaVdWd00iVn32STp',
      webhooks: {
        signatures: {
          'disputes': process.env.AZIBO_STRIPE_SIGNATURE_DISPUTES || false,
          'charges': process.env.AZIBO_STRIPE_SIGNATURE_CHARGES || false,
          'accounts-person':
            process.env.AZIBO_STRIPE_SIGNATURE_ACCOUNTS || false,
          'payouts': process.env.AZIBO_STRIPE_SIGNATURE_PAYOUTS || false,
          'external-accounts':
            process.env.AZIBO_STRIPE_SIGNATURE_EXTERNAL_ACCOUNTS || false,
          'payment-methods':
            process.env.AZIBO_STRIPE_SIGNATURE_PAYMENT_METHODS || false,
        },
      },
      platformAccountId: 'acct_1FM0GjCXwSaMEZPm',
      payouts: {
        interval: process.env.AZIBO_STRIPE_PAYOUTS_INTERVAL || 'manual',
        manual: 'manual',
        daily: 'daily',
        delayDays: process.env.AZIBO_STRIPE_PAYOUTS_DELAY_DAYS || 6,
      },
    },
    platformAccountId: ['PROD', 'STABLE'].includes(process.env.ENV)
      ? 'acct_16GXUqHpSWtA1ss2'
      : 'acct_1ENginKTFJkLeHsP',
    apiKey: process.env.STRIPE_KEY || '',
    publicKey: process.env.STRIPE_PUBLIC_KEY || '',
    payouts: {
      interval: process.env.STRIPE_PAYOUTS_INTERVAL || 'manual',
      manual: 'manual',
      daily: 'daily',
      delayDays: process.env.STRIPE_PAYOUTS_DELAY_DAYS || 6,
    },
    identity: {
      enabled: process.env.STRIPE_ID_ENABLED !== 'false',
      verificationSessionDashboard: isProd
        ? 'https://dashboard.stripe.com/identity/verification-sessions/'
        : 'https://dashboard.stripe.com/test/identity/verification-sessions/',
    },
    webhooks: {
      // Stripe Dashboard’s Webhooks -> Select an endpoint that you want to obtain the secret for, then click the "Click to reveal" button.
      signatures: {
        'disputes': process.env.STRIPE_SIGNATURE_DISPUTES || false,
        'charges': process.env.STRIPE_SIGNATURE_CHARGES || false,
        'sigma': process.env.STRIPE_SIGNATURE_SIGMA || false,
        'notify': process.env.STRIPE_SIGNATURE_NOTIFY || false,
        'identity': process.env.STRIPE_SIGNATURE_IDENTITY || false,
        'accounts-person': process.env.STRIPE_SIGNATURE_ACCOUNTS || false,
        'payouts': process.env.STRIPE_SIGNATURE_PAYOUTS || false,
        'external-accounts':
          process.env.STRIPE_SIGNATURE_EXTERNAL_ACCOUNTS || false,
        'payment-methods':
          process.env.STRIPE_SIGNATURE_PAYMENT_METHODS || false,
      },
    },
    chargesMinAmountLimit: 50,
    chargesMaxAmountLimit: 9999999, // in cents
  },
  payoutLedger: {
    enabled: process.env.ENABLE_PAYOUT_LEDGER || true,
  },
  plaid: {
    azibo: {
      clientId: process.env.AZIBO_PLAID_CLIENT_ID || '',
      secret: process.env.AZIBO_PLAID_SECRET || '',
      publicKey: process.env.AZIBO_PLAID_PUBLIC_KEY || '',
      getBalanceTimeout: process.env.PLAID_GET_BALANCE_TIMEOUT || 7000,
      env: process.env.PLAID_ENV || 'sandbox',
    },
    clientId: process.env.PLAID_CLIENT_ID || '',
    secret: process.env.PLAID_SECRET || '',
    publicKey: process.env.PLAID_PUBLIC_KEY || '',
    env: process.env.PLAID_ENV || 'sandbox',
    getBalanceTimeout: process.env.PLAID_GET_BALANCE_TIMEOUT || 7000,
    webhooks: {
      endpoints: {
        item: process.env.PLAID_ITEM_ENDPOINT || '/plaid/item',
      },
    },
  },
  reiHub: {
    webhooks: {
      signatureKey: {
        dev: 'duckgoesquack123',
        prod: process.env.REI_HUB_SIGNATURE_KEY || '',
      },
    },
    apiUrl: process.env.REI_HUB_API_URL || '',
    apiVersion: process.env.REI_HUB_API_VERSION || '',
    apiKey: process.env.REI_HUB_API_KEY || '',
  },
  shareable: {
    apiUrl:
      process.env.SHAREABLE_API_URL ||
      'https://rentals-api-ext.shareable.com/v2',
    clientId:
      process.env.SHAREABLE_CLIENT_ID || '-ZREbBhsGpikQHUM96znAOD2vz70yC4',
    // this 3 keys are encoded using encodeURIComponent.
    // You need to use decodeURIComponent before sending them to the API
    apiKey1:
      process.env.SHAREABLE_API_KEY1 ||
      'MEUyMUpxWm1wQE9EWkZmVjZ1R0FacklzOHozRGVXS1VxaipmYUs=',
    apiKey2:
      process.env.SHAREABLE_API_KEY2 ||
      'MzJpI2xWY1l4TFokTElHaTRjUEx6SmpZR3B0I3dIMkZeMjY=',
    apiKey3:
      process.env.SHAREABLE_API_KEY3 ||
      'ZSEmaExyQUhRcGFWUjNLMGxxM2ZHYnJuNlAjQFNWTE5CRG9xY0DigIxJVEp3TDRJa3d0',
  },
  rentbutter: {
    apiUrl:
      process.env.RENTBUTTER_API_URL ||
      'https://api.staging.rentbutter.com/v1/',
    secret:
      process.env.RENTBUTTER_SECRET ||
      'NUGaX7wh8ZoucX34KQ-hTfHiP3Tk22z6QuNlFzGvWjDHsBQ6GoFyozxcPazKd8Sr', // TODO should we encode it?
    clientId:
      process.env.RENTBUTTER_CLIENT_ID || 'pJDgXfok8Nt1Kq9vRw9KMd7FkEdmMoRC',
    premiumAccountId: process.env.RENTBUTTER_PREMIUM_ACCOUNT_ID || '272',
  },
  zillow: {
    url: process.env.ZILLOW_URL || 'https://hotpads.com/',
    feedId: process.env.ZILLOW_FEED_ID || false,
    writeKey: process.env.ZILLOW_WRITE_KEY,
    apiKey: process.env.ZILLOW_API_KEY || '',
  },
  facebook: {
    systemToken: process.env.FB_SYSTEM_TOKEN || false,
    pageToken: process.env.FB_PAGE_TOKEN || false,
    appId: process.env.FB_APP_ID || '',
  },
  hubspot: {
    portalId: process.env.HUBSPOT_PORTAL_ID,
    signupForm: process.env.HUBSPOT_SIGNUP_FORM_GUID,
  },
  helloSign: {
    clientId: process.env.HELLO_SIGN_CLIENT_ID || false,
    testMode: process.env.HELLO_SIGN_TEST_MODE === 'true',
    apiKey: process.env.HELLO_SIGN_API_KEY || false,
  },
  atatus: {
    apiKey: process.env.ATATUS_LICENSE_KEY || false,
    ignoredOpNames: (process.env.ATATUS_IGNORED_OP_NAMES || '')
      .split(/, */g)
      .filter((f) => !!f),
  },
  openai: {
    apiKey: process.env.OPENAI_API_KEY || false,
  },
  sendgridEmailVerification: {
    apiKey: process.env.SENDGRID_EMAIL_VERIFICATION_API_KEY || false,
  },
  chromePath: process.env.CHROME_PATH || '/usr/bin/google-chrome-stable',
  settings: {
    partner_stable_test_access_key:
      process.env.PARTNER_STABLE_TEST_ACCESS_KEY || '',
    send_payment_request_notifications_limit: Number(
      process.env.SEND_PAYMENT_REQUEST_NOTIFICATIONS_LIMIT || 6000,
    ),
    send_got_paid_email_limit_per_run:
      process.env.SEND_GOT_PAID_EMAIL_LIMIT_PER_RUN || 10_000,
    TU_polling_interval: 5,
    TU_product_bundle: 'PackageCore',
    TU_product_bundle_with_evictions: 'PackageCorePlusEviction',
    TU_max_renter_age: 125,
    TU_min_renter_age: 18,
    YS_retry_interval: 1,
    ach_fee: 2,
    default_application_fee: 55,
    premium_application_fee: 45,
    lease_ending_soon_threshold: process.env.LEASE_ENDING_SOON_THRESHOLD || 120,
    esign_fee: 15,
    lease_agreement_fee: 59,
    lease_addendum_fee: 19,
    premium_price_signup_default: 119,
    forms_pack_price: 199,
    maintenance_plus_price: 149,
    insurance_price: 11,
    report_listings_email: 'badlisting@turbotenant.com',
    authorization_statement:
      "The undersigned Applicant declares that the information contained in this Rental Application is true, complete and correct, and understands and agrees that any false statements or representations identified herein may result in rejection, without further notice, of this and any future applications for tenancy in housing. The undersigned specifically authorizes and directs any and all persons or entities named by Applicant herein to receive, provide, and exchange with the property owner, it's principals, agents and employees, any information pertaining to me, including but not limited to confidential information pertaining to my credit and payment history, the opinions and recommendations of my personal and employment references, my rental history, my criminal history, my driving record, my military background, my civil listings, my educational background, and any professional licenses. I hereby waive any right of action now or hereafter accruing against any person or entity as a consequence of the release or exchange of such confidential information. By agreeing to these terms, I authorize the investigation and release of any and all information pertaining to the statements and representations contained herein, including but not limited to release of my confidential credit report to Turbo Tenant its principals and/or the owner(s) of any property which I am applying to occupy.",
    amenities_priority: [
      'furnished',
      'swimming_pool',
      'hottub_spa',
      'fenced_yard',
      'microwave',
      'lawn',
      'dishwasher',
      'fitness_center',
      'on_site_laundry',
      'fireplace',
      'dryer',
      'washer',
      'intercom',
      'range_oven',
      'secure_building',
      'security_cameras',
      'no_smoking',
      'washer_dryer_hookups',
      'vaulted_ceiling',
      'refrigerator',
      'garbage_disposal',
      'freezer',
      'high_speed_internet',
      'wired_for_internet',
      'near_parks',
      'bicycle_parking',
      'cable_ready',
      'trash_compactor',
    ],
    turbotenant_official_number: '719-966-RENT',
    telephone_mask: '999-999-9999',
    smartmove_manual_verification_message:
      'We are unable to verify your identity online.  Please call TransUnion customer support at (866) 775-0961 for assistance with completing your application',
    transunion_shareable_manual_verification_message:
      'We are unable to verify your identity online.  Please call TransUnion customer support at (866) 775-0961 for assistance with completing your application',
  },
  twilio: {
    accountSid: process.env.TWILIO_ACCOUNT_SID,
    authToken: process.env.TWILIO_AUTH_TOKEN,
    fromNumber: process.env.TWILIO_FROM_NUMBER || '',
    phoneMaskingAccountSid: process.env.TWILIO_PHONE_MASKING_ACCOUNT_SID || '',
    phoneMaskingAuthToken: process.env.TWILIO_PHONE_MASKING_AUTH_TOKEN || '',
    phoneMaskingMessagingServiceSid:
      process.env.TWILIO_PHONE_MASKING_MESSAGING_SERVICE_SID || '',
    twimlAppSid: process.env.TWIML_APP_SID || '',
    defaultServiceId: process.env.TWILIO_MS_VERIFICATION || false,
    verificationServiceId: process.env.TWILIO_MS_VERIFICATION || false,
    messagingServiceId: process.env.TWILIO_MS_NOTIFICATION || false,
    preScreenerServiceId: process.env.TWILIO_PRE_SCREENER || false,
    accountNotificationsServiceId:
      process.env.TWILIO_ACCOUNT_NOTIFICATION_SID || false,
    listingNotificationsServiceId:
      process.env.TWILIO_LISTING_NOTIFICATION || false,
    verificationCallResponseUrl: `${process.env.API_URL}public/twilio/verificationcallresponse`,
    testPhoneCallResponseUrl: 'public/twilio/testPhoneCall',
  },
  captcha: {
    secret_v2: process.env.RECAPTCHA_SECRET_KEY,
    secret_v3: process.env.RECAPTCHA_LOGIN_SECRET_KEY || false,
    secret_v3_mobile: process.env.RECAPTCHA_SECRET_KEY_MOBILE || false,
    site_v3_mobile: process.env.RECAPTCHA_SITE_KEY_MOBILE || false,
    score_threshold: Number(process.env.RECAPTCHA_SCORE_THRESHOLD || 0.8),
    ignoredEmail: (matchesRegex) => {
      try {
        if (!matchesRegex) {
          return false;
        }
        const regex = new RegExp(
          process.env.RECAPTCHA_IGNORED_REGEX || '^.+smoke@turbotenant.com$',
        );
        return !!matchesRegex.match(regex);
      } catch {
        return false;
      }
    },
  },
  sure: {
    api: {
      url: process.env.SURE_API_URL || 'https://api.trysureapp.com/api/partner',
      secretKey:
        process.env.SURE_SECRET_KEY ||
        'sIF7epFEJMyfoqYubPLf1cCZgjXmn8d650AQ2S9UBGwrxTDNW',
      publicKey:
        process.env.SURE_PUBLIC_KEY ||
        'pcNqCxA18HdI9nWems3BSQ7RigLfzuMTjUl4J6aywof2bFtPG',
    },
    sftp: {
      host: process.env.SURE_SFTP_HOST,
      user: process.env.SURE_SFTP_USER,
      ftpPass: process.env.SURE_SFTP_PASS || process.env.SURE_FTP_PASS || false,
      key: process.env.SURE_SFTP_KEY || false,
    },
  },
  sqs: {
    accessKeyId: process.env.SQS_ACCESS_KEY_ID || false,
    secretAccessKey: process.env.SQS_SECRET_ACCESS_KEY || false,
    region: (process.env.SQS_DEFAULT_REGION = 'us-west-2'),
    namespace: process.env.SQS_NAMESPACE || 'dev',
    consumerTimeout: Number(process.env.SQS_CONSUMER_TIMEOUT) || 0,
    endpointQueueUrl: process.env.SQS_ENDPOINT_QUEUE_URL || false,
    defaultQueueUrl: process.env.SQS_DEFAULT_QUEUE_URL || false,
    queueUrls: {
      elasticSearchIndexing:
        (process.env.SQS_QUEUE_URL_ES_INDEXING !== 'false' &&
          process.env.SQS_QUEUE_URL_ES_INDEXING) ||
        false,
      notifyUnreadMessages:
        (process.env.SQS_QUEUE_URL_NOTIFY_UNREAD_MESSAGES !== 'false' &&
          process.env.SQS_QUEUE_URL_NOTIFY_UNREAD_MESSAGES) ||
        false,
      stripePayoutEvent:
        (process.env.SQS_QUEUE_URL_STRIPE_PAYOUT_EVENTS !== 'false' &&
          process.env.SQS_QUEUE_URL_STRIPE_PAYOUT_EVENTS) ||
        false,
      stripeChargeEvent:
        (process.env.SQS_QUEUE_URL_STRIPE_CHARGE_EVENTS !== 'false' &&
          process.env.SQS_QUEUE_URL_STRIPE_CHARGE_EVENTS) ||
        false,
      hellosignDownloadDocuments:
        (process.env.SQS_QUEUE_URL_HELLOSIGN_DOWNLOAD_DOCUMENTS !== 'false' &&
          process.env.SQS_QUEUE_URL_HELLOSIGN_DOWNLOAD_DOCUMENTS) ||
        false,
      workerJobAutopay:
        (process.env.SQS_QUEUE_URL_WORKER_JOB_AUTOPAY !== 'false' &&
          process.env.SQS_QUEUE_URL_WORKER_JOB_AUTOPAY) ||
        false,
      workerJobCraiglistListing:
        (process.env.SQS_QUEUE_URL_WORKER_JOB_CRAIGSLIST_LISTING !== 'false' &&
          process.env.SQS_QUEUE_URL_WORKER_JOB_CRAIGSLIST_LISTING) ||
        false,
      cometChatMessageSent:
        (process.env.SQS_QUEUE_URL_CC_MESSAGE_SENT !== 'false' &&
          process.env.SQS_QUEUE_URL_CC_MESSAGE_SENT) ||
        false,
      livbleResyncEvent:
        (process.env.SQS_QUEUE_URL_LVBLE_RESYNC_EVENTS !== 'false' &&
          process.env.SQS_QUEUE_URL_LVBLE_RESYNC_EVENTS) ||
        false,
    },
  },
  segment: {
    analyticsBulkUpdates: process.env.ANALYTICS_BULK_UPDATES !== 'false',
    maxUsers: process.env.SEGMENT_MAX_USERS || 3000,
    owners: {
      writeKey: process.env.SEGMENT_OWNERS_WRITE_KEY || false,
    },
    renters: {
      writeKey: process.env.SEGMENT_RENTERS_WRITE_KEY || false,
    },
    instantUpdates: process.env.SEGMENT_INSTANT_UPDATES !== 'false',
  },
  customerio: {
    owners: {
      siteId: process.env.CIO_OWNERS_SITE_ID || 'a9ec278d79a7d24ac205',
      apiKey: process.env.CIO_OWNERS_API_KEY || 'df344c4276405e130be1',
    },
    renters: {
      siteId: process.env.CIO_RENTERS_SITE_ID || 'd70288b9e38a6943b0a7',
      apiKey: process.env.CIO_RENTERS_API_KEY || 'fc8d5823df931df2ef0d',
    },
  },
  updateUserActivity:
    process.env.UPDATE_USER_ACTIVITY !== 'false' &&
    process.env.NODE_ENV !== 'test',
  updateGeneralUserActivity:
    process.env.UPDATE_GENERAL_USER_ACTIVITY !== 'false',
  unsubscribeGroups: {
    // RENTER_EMAILS_FROM_TURBOTENANT
    'portal_welcome_R': 'renters_from_turbotenant',
    'book_movers_book_internet_lease_added_R': 'renters_from_turbotenant',
    'book_movers_book_internet_prescreen_R': 'renters_from_turbotenant',
    'book_movers_book_internet_application_R': 'renters_from_turbotenant',
    'rcr_promo_R': 'renters_from_turbotenant',

    // RENTER_RENTAL_NOTIFICATIONS
    'invite_to_apply_R': 'renters_rental_notifications',
    'invite_to_apply_4_R': 'renters_rental_notifications',
    'invite_to_apply_24_R': 'renters_rental_notifications',
    'app_submitted_R': 'renters_rental_notifications',
    'app_and_idv_submitted_R': 'renters_rental_notifications',
    'finish_app_R': 'renters_from_turbotenant',
    'idv_reminder_r': 'renters_from_turbotenant',
    'screening_request_R': 'renters_from_turbotenant',
    'screening_requested_AppPays_R': 'renters_rental_notifications',
    'screening_requested_reminder_R': 'renters_rental_notifications',
    'screening_requested_AppPays_reminder_R': 'renters_rental_notifications',
    'reapprove_screening_request_R': 'renters_rental_notifications',
    'screening_delivered_R': 'renters_rental_notifications',
    'screening_delivered_appPays_R': 'renters_rental_notifications',
    'screening_denied_R': 'renters_rental_notifications',
    'ri_required_R': 'renters_rental_notifications',
    'ri_required_reminder_R': 'renters_from_turbotenant',
    'co_applicant_invite_r': 'renters_from_turbotenant',
    'questionnaire_request_lead': 'renters_rental_notifications',
    'questionnaire_request_reminder_lead': 'renters_rental_notifications',
    'questionnaire_request_reminder2_lead': 'renters_rental_notifications',
    'invite_to_listing_R': 'renters_rental_notifications',
    'leasedoc_shared_R': 'renters_rental_notifications',
    'upload_doc_R': 'renters_rental_notifications',
    'request_CR_R': 'renters_rental_notifications',
    'CR_approved_R': 'renters_rental_notifications',

    // RENTER_MESSAGES_FROM_LANDLORD
    'new_message_1st_R': 'renters_messages_from_landlord',
    'new_message_R': 'renters_messages_from_landlord',

    // LANDLORD_NEW_LEADS | Account - Leads
    'lead_LL': 'landlord_new_leads',
    'lead_noprescreener_LL': 'landlord_new_leads',
    'first_lead_ll': 'landlord_new_leads',
    'questionnaire_complete_ll': 'landlord_new_leads',
    'lead_TTlisting_questionnaire_LL': 'landlord_new_leads',
    'lead_TTlisting_LL': 'landlord_new_leads',
    'lead_LL_batched': 'landlord_new_leads',

    // LANDLORD_MESSAGES_FROM_RENTERS | Account - Messages from Renters
    'new_message_LL': 'landlord_messages_from_renters',
    'new_message_1st_LL': 'landlord_messages_from_renters',

    // LANDLORD_LEASES | Account - Leases
    'ri_purchased_LL': 'landlord_leases',
    'ri_uploaded_LL': 'landlord_leases',
    'ri_canceled_LL': 'landlord_leases',
    'finish_lease_agreement1_LL': 'landlord_leases',
    'finish_lease_agreement2_LL': 'landlord_leases',
    'ri_expired_LL': 'landlord_leases',
    'lease_adden90': 'landlord_leases',
    'finish_lease_addendum1_LL': 'landlord_leases',
    'finish_lease_addendum2_LL': 'landlord_leases',
    'purchase_lease_addendum1_LL': 'landlord_leases',
    'purchase_lease_addendum2_LL': 'landlord_leases',
    'purchase_lease_agreement_premium_promo_LL': 'landlord_leases',
    'purchase_lease_addendum_premium_promo_LL': 'landlord_leases',
    'purchase_lease_agreement1_LL': 'landlord_leases',
    'purchase_lease_agreement2_LL': 'landlord_leases',
    'lease_end_checklist_not_started_120days_ll': 'landlord_leases',
    'lease_end_checklist_not_started_90days_ll': 'landlord_leases',
    'lease_end_checklist_not_started_60days_ll': 'landlord_leases',
    'lease_end_checklist_not_started_30days_ll': 'landlord_leases',
    'lease_end_checklist_started_120days_ll': 'landlord_leases',
    'lease_end_checklist_started_90days_ll': 'landlord_leases',
    'lease_end_checklist_started_60days_ll': 'landlord_leases',
    'lease_end_checklist_started_30days_ll': 'landlord_leases',
    'lease_end15_ll': 'landlord_leases',
    'lease_end0_ll': 'landlord_leases',
    'ri_reminder_ll': 'landlord_leases',
    'how_ri_works_LL': 'landlord_leases',
    'CR_submitted_LL': 'landlord_leases',

    // LANDLORD_PROPERTIES | Account - Property
    'property_being_marketed_LL': 'landlord_properties',
    'doc_exp_LL': 'landlord_properties',
    'renew_marketing_LL': 'landlord_properties',

    // LANDLORD_APPLICANTS | Account - Applicants
    'first_app_recieved_LL': 'landlord_applicants',
    'app_recieved_LL': 'landlord_applicants',
    'screening_received_LL': 'landlord_applicants',
    'screening_denied_LL': 'landlord_applicants',
    'screening_report_requested_LLpaid_LL': 'landlord_applicants',
    'cold_app_LL': 'landlord_applicants',
    'cold_app_reminder_LL': 'landlord_applicants',

    // LANDLORD_CHECK_INS_MAINTENANCE | Account - Check-ins & Maintenance Feedback
    'maintenance_request_feedback_LL': 'landlord_check_ins_maintenance',
    'check-ins_notification_LL': 'landlord_check_ins_maintenance',
    'maintenance_request_notification_LL': 'landlord_check_ins_maintenance',

    // LANDLORD_PROMOTIONS | Updates & Offers - Promotions
    'landlord_insurance_30 days_lease_start_LL': 'landlord_promotions',
    'landlord_insurance_45_days_lease_end_LL': 'landlord_promotions',
    'referral_ask_first': 'landlord_promotions',

    // LANDLORD_PLATFORM_UPDATES | Updates & Offers - Platform Updates
    'got_paid_ll': 'landlord_platform_updates',
    // LANDLORD_THEKEY_WEEKLY_NEWSLETTER | Updates & Offers - TheKey Weekly Newsletter
    // LANDLORD_WEBINARS_AND_NETWORKING_EVENTS | Updates & Offers - Webinars & Networking Events
    // LANDLORD_FEEDBACK_OPPORTUNITIES | Updates & Offers - Feedback Opportunities
  },
  pushNotification: {
    apiUrl: 'https://exp.host/--/api/v2/push/send',
  },
  siftScience: {
    apiKey: '',
    accountId: process.env.SIFT_SCIENCE_ACCOUNT_ID || '',
    emailsInclude: process.env.SIFT_SCIENCE_EMAILS_INCLUDE || '',
    emailsExclude: process.env.SIFT_SCIENCE_EMAILS_EXCLUDE || '',
    signatureKey: process.env.SIFT_SCIENCE_SIGNATURE_KEY || '',
  },
  skipVerifyFeed: process.env.SKIP_VERIFY_FEED || false,
  logger: {
    expressIgnoredRoutes: process.env.LOGGER_EXPRESS_IGNORED_ROUTES
      ? process.env.LOGGER_EXPRESS_IGNORED_ROUTES.split(',')
      : [],
    console_levels: process.env.LOGGER_CONSOLE_LEVELS || '',
    console_default_level: process.env.LOGGER_CONSOLE_DEFAULT_LEVEL || 'http',
    logRequestPayload: process.env.LOGGER_REQUEST_PAYLOAD === 'true',
  },
  requestLimiter: {
    userRequestLimiter:
      process.env.RATE_LIMITER_USER_REQUEST !== 'false' &&
      process.env.NODE_ENV !== 'test',
    userRequestInterval: Number(
      process.env.RATE_LIMITER_USER_REQUEST_INTERVAL || 30000,
    ),
    userRequestMaxInInterval: Number(
      process.env.RATE_LIMITER_USER_REQUEST_MAX_IN_INTERVAL || 100,
    ),
    interval: process.env.RATE_LIMITER_INTERVAL || 30000,
    maxInInterval: process.env.RATE_LIMITER_MAX_IN_INTERVAL || 10,
    disabled: process.env.DISABLE_REQUEST_LIMITER || false,
    redis: process.env.REQUEST_LIMITER_REDIS || '',
  },
  ipFiltering: {
    // These represent an IP range (2 IPs separated by commas) or a single IP
    transunion:
      process.env.SHAREABLE_OUTBOUND_IP !== 'false'
        ? process.env.SHAREABLE_OUTBOUND_IP ||
          '66.175.245.1,66.175.245.7,24.206.84.74,24.206.84.75,24.206.76.66,24.206.76.67'
        : false,
  },
  limits: {
    maximumAmountForFastPayout:
      process.env.MAXIMUM_AMOUNT_FOR_FAST_PAYOUT || 1000000, // $10,000 in cents
  },
  trialPeriodInDays: process.env.TRIAL_PERIOD_IN_DAYS || 30,
  trialExtendPeriodInDays: process.env.TRIAL_EXTEND_PERIOD_IN_DAYS || 14,
  // we can't add any more vars to the env, so for now we are putting the values in a separated file
  elasticSearch: {
    user:
      process.env.ELASTIC_SEARCH_USER || process.env.ES_USER || 'es-dev-user',
    password:
      process.env.ELASTIC_SEARCH_PASSWORD ||
      process.env.ES_PASSWORD ||
      'TT2021ESdev!',
    node:
      process.env.ELASTIC_SEARCH_NODE ||
      process.env.ES_NODE ||
      'https://search-tt-es-dev-rzsm4jvu5eywcrx66phvdgcgmq.us-west-2.es.amazonaws.com/',
    featureEnabled:
      process.env.ELASTIC_SEARCH_ENABLED === 'true' ||
      process.env.ES_ENABLED === 'true',
    syndicationsSearchEnabled:
      process.env.ELASTIC_SEARCH_SYNDICATIONS_ENABLED !== 'false' &&
      process.env.ES_SYNDICATIONS_ENABLED !== 'false',
    ownersSearchEnabled: process.env.ES_OWNERS_ENABLED === 'true',
    paymentAlertsEnabled: process.env.ES_PAYMENT_ALERTS_ENABLED !== 'false',
    allowBulkIndexing: process.env.ES_ALLOW_BULK_INDEXING === 'true',
  },
  autopay: {
    limitPerRun: Number(process.env.AUTOPAY_LIMIT_PER_RUN || 2500),
    maxRunTimeMinutes: Number(process.env.AUTOPAY_MAX_RUN_TIME_MINUTES || 26),
    concurrency: process.env.AUTOPAY_CONCURRENCY || 5,
  },
  lateFee: {
    limitPerRun: Number(process.env.LATE_FEE_LIMIT_PER_RUN || 1000),
    maxRunTimeMinutes: Number(process.env.LATE_FEE_MAX_RUN_TIME_MINUTES || 50),
    concurrency: process.env.LATE_FEE_CONCURRENCY || 50,
  },
  reminders: {
    chargeDueTodayLimitPerRun: Number(
      process.env.CHARGE_DUE_TODAY_LIMIT_PER_RUN || 3200,
    ),
  },
  updaterHost:
    process.env.UPDATER_HOST ||
    'https://staging-partner-cart.updater.com/provider/qualify',
  sitemap: {
    urlsPerMap: 25000,
    location: 'uploads/sitemaps',
    index: 'uploads/sitemap.xml',
  },
  credit_reporting: {
    location: 'rent_reporting',
    incomplete_form_worker: {
      maxRunTimeMinutes: Number(
        process.env.CREDIT_REPORTING_INCOMPLETE_FORM_MAX_RUN_TIME_MINUTES || 45,
      ),
      concurrency: Number(
        process.env.CREDIT_REPORTING_INCOMPLETE_FORM_CONCURRENCY || 30,
      ),
      limit: Number(process.env.CREDIT_REPORTING_INCOMPLETE_FORM_LIMIT || 1000),
    },
  },
  academy: {
    signature: process.env.ACADEMY_WEBHOOK_SIGNATURE || '',
    apiUrl:
      process.env.ACADEMY_API_URL ||
      'https://turbotenant.getlearnworlds.com/admin/api/',
    clientSecret: process.env.ACADEMY_CLIENT_SECRET || '',
    clientId: process.env.ACADEMY_CLIENT_ID || '',
    accessToken: process.env.ACADEMY_ACCESS_TOKEN || '',
    progressUpdateFrequency: process.env.PROGRESS_UPDATE_FREQUENCY || 12,
    host: process.env.ACADEMY_HOST || 'https://academy.turbotenant.com',
  },
  geocode: {
    geoMappingEnabled: process.env.GEO_MAPPING_ENABLED === 'true',
    geocodeApiKey:
      process.env.GEOCODE_API_KEY || 'AIzaSyAnBe0o7DKC4G-FcERczMLAZwei3-xov_8',
    apiUrl:
      process.env.GEOCODE_API_URL ||
      'https://maps.googleapis.com/maps/api/geocode/json?',
  },
  ownwell: {
    apiKey: process.env.OWNWELL_API_KEY || '',
  },
  cometchat: {
    apiKey:
      process.env.COMETCHAT_API_KEY ||
      '2a55d75a3bd3ef0a1188c5a7cbab587e372f82c5',
    appId: process.env.COMETCHAT_APP_ID || '2339015b22ad048d',
    appRegion: 'us',
    webhookAuthKey:
      process.env.COMETCHAT_WEBHOOK_AUTH_KEY ||
      'ZGV2X3R1cmJvdGVuYW50X3dlYmhvb2s6ZGV2dHRjY3dlYmhvb2sh',
  },
  formsPacksNumber: 32,
  stripeAmountFactor: 100, // https://stripe.com/docs/currencies#zero-decimal
  stripePaymentsDashboardUrl: isProd
    ? 'https://dashboard.stripe.com/payments/'
    : 'https://dashboard.stripe.com/test/payments/',
  stripeConnectAccountURL: isProd
    ? 'https://dashboard.stripe.com/connect/accounts/'
    : 'https://dashboard.stripe.com/test/connect/accounts/',
  testPhoneNumbers: ['5005550006', '5005550005'],
  kycURL:
    'https://support.turbotenant.com/en/articles/6029545-what-is-kyc-and-why-does-it-matter',
  paymentAlerts: {
    riskLevelScores: {
      high: process.env.PAYMENT_ALERTS_HIGH_RISK_SCORE || 3,
      medium: process.env.PAYMENT_ALERTS_MEDIUM_RISK_SCORE || 2,
      low: process.env.PAYMENT_ALERTS_LOW_RISK_SCORE || 1,
    },
    analyzers: {
      paymentAfterNMonths: {
        numOfMonths:
          Number(process.env.PAYMENT_ALERTS_PAYMENT_AFTER_N_MONTHS) || 6,
      },
    },
  },
  payoutLedgerAdminPermissions: process.env.PAYOUT_LEDGER_ADMINS
    ? process.env.PAYOUT_LEDGER_ADMINS.split(',').map((e) => e.trim())
    : [
        'tony@turbotenant.com',
        'melissa@turbotenant.com',
        'michael@turbotenant.com',
        'laura@turbotenant.com',
        'katie@turbotenant.com',
        'mitri@turbotenant.com',
        'christina@turbotenant.com',
        'tom@turbotenant.com',
      ],
  updateDisputedChargeAdminPermissions: process.env.DISPUTED_CHARGE_ADMINS
    ? process.env.DISPUTED_CHARGE_ADMINS.split(',').map((e) => e.trim())
    : [
        'tony@turbotenant.com',
        'melissa@turbotenant.com',
        'michael@turbotenant.com',
        'laura@turbotenant.com',
        'katie@turbotenant.com',
        'mitri@turbotenant.com',
        'christina@turbotenant.com',
        'tom@turbotenant.com',
      ],

  deleteLeaseDocumentAdminPermissions: process.env.DELETE_LEASE_DOCUMENTS_ADMINS
    ? process.env.DELETE_LEASE_DOCUMENTS_ADMINS.split(',').map((e) => e.trim())
    : [
        'tony@turbotenant.com',
        'melissa@turbotenant.com',
        'deja@turbotenant.com',
        'michael@turbotenant.com',
        'laura@turbotenant.com',
        'katie@turbotenant.com',
      ],

  updateWizardDocumentAdminPermissions: process.env.UPDATE_LEASE_ADDENDUM_ADMINS
    ? process.env.UPDATE_LEASE_ADDENDUM_ADMINS.split(',').map((e) => e.trim())
    : [
        'tony@turbotenant.com',
        'melissa@turbotenant.com',
        'deja@turbotenant.com',
        'michael@turbotenant.com',
        'laura@turbotenant.com',
        'katie@turbotenant.com',
      ],
  lula: {
    baseUrl:
      process.env.LULA_BASE_URL ||
      (isProd
        ? 'https://partners.api.lula.io/v1'
        : 'https://partners.api.lula-staging.com/v1'),
    clientId: process.env.LULA_CLIENT_ID || '11sp2cl8n75vqrh4c7880cppil',
    clientSecret:
      process.env.LULA_CLIENT_SECRET ||
      '1rl596vqlb5c4p0bapfg17n9h80srlab633tkqols748fg0dnls9',
    webhookAuthKey: process.env.LULA_WEBHOOK_AUTH_KEY || 'hcuh949o299d1zfc',
  },
  seon: {
    baseUrl:
      process.env.SEON_BASE_URL ||
      'https://api.us-east-1-main.seon.io/SeonRestService',
    apiKey: process.env.SEON_API_KEY || '537ca48f-25f4-465a-b92d-71ff46278483',
  },
  lvble: {
    azibo: {
      apiKey:
        process.env.LVBLE_AZIBO_API_KEY ||
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOiI5OGIzZDA1YzMzODM0ODZkODNhYWMyYjgzZGU5YmZmMiJ9.c9VZAJMl62QxjhKID3WrH-F0Tn4fipvnCS1lI9_pA6CdT7n-s6V1S1ojIlcFYNZBPVAMdyQB2xWO966VrAxf6rlaQbzk8aAdKIY96LOR2hvDaJAF3_j0E-lkKyz7TAJsL-Af-E3e5HME2fDRgw0GYXD3z86OHviuGaVOmH_DAVbFuMel_rQY9LA_a0SmMbJOhhdUFIlXCJLgH2RS2DOV-diMucb_n_u_-wVcQ4GrcrPhRC98FSXr-ABxV77AGLJkNLYorthzrL8hf3xB3slML1UhKm2_KPtfRtxkvS9HH-B8FDgMZM4oVkTzpNM6ZVKN5gAC4h4cb1jur2hNf4besw',
      customerId: process.env.LVBLE_AZIBO_CUSTOMER_ID || 'cus_PWgGk6puDJzBmW',
      sources: {
        ach:
          process.env.LVBLE_AZIBO_STRIPE_SOURCE_ID ||
          'ba_1OhdSBCXwSaMEZPm4yqCdCLC',
      },
    },
    apiKey:
      process.env.LVBLE_API_KEY ||
      'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOiI0MGRlOWM1Nzk2MDM0NWQ2OGI0ZGM1YjQxYjgzMGI4YyJ9.GzIS3N60TEF3lvzQlJ1x6qRtn4lkC8tIA7w_s4w0VmK87i58HDCf_7RRmbyjzgidbzUeeRT75_BXheyHBSL0RagL-kpQDHBS63CYd_Z3QvXBhuA4hhYtNwDGdqFHr8L7-jvlUN55oTLd9IrBcH22UI9fKXjN4mLFF-CnJziisD8t3qtYjmbKtscwkxszS2DRG7FXcyfq-8TmFpLqHCaaD_qCrxGIvMX1KLFqG-lorFx7xYKTCD_CFFUrEQ5hofT-5SNjHzEkix3t3VBFzrXdKTj0V3HLDWpKkLe5nVDH6lWDIQx06XIU_A4KHaunUrSFFC05PnLCiGY7eyULIghBJQ',
    apiUrl:
      process.env.LVBLE_API_URL ||
      'https://api.partners.sandbox.nowlvble.com/v1/',
    accountHolderName: 'Lvble Disbursements',
    accountHolderType: 'company', // or individual
    customerId: process.env.LVBLE_CUSTOMER_ID || 'cus_OO9Xm1FUzBKXp3',
    sources: {
      ach: process.env.LVBLE_STRIPE_SOURCE_ID || 'ba_1NbNEVKTFJkLeHsP3zkFsrbK',
      card:
        process.env.LVBLE_STRIPE_SOURCE_ID_CARD ||
        'card_1Ow5pfKTFJkLeHsPF04KWu1i',
    },
    paymentsCycleStartDay: process.env.LVBLE_PAYMENTS_CYCLE_START_DAY || 28,
    paymentsCycleEndDay: process.env.LVBLE_PAYMENTS_CYCLE_END_DAY || 9,
    receiptEmail: process.env.LVBLE_RECEIPT_EMAIL || 'michael@turbotenant.com',
    webhooks: {
      secrets: {
        settlements:
          process.env.LVBLE_SETTLEMENT_WEBHOOK_SECRET ||
          'whsec_DIFs02NtAxi1Rd7hatMdBTD6oDHrlbTB',
        resyncPortalInfo:
          process.env.LVBLE_RESYNC_WEBHOOK_SECRET ||
          'whsec_316C1OYwO+UNlLqff3RpJ/Dk24d2ktQx',
        azibo: {
          settlements:
            process.env.LVBLE_SETTLEMENT_WEBHOOK_URL ||
            'whsec_SfWKqSH0cus/B15btT58kz8D1E6dhgfk',
          resyncPortalInfo:
            process.env.LVBLE_RESYNC_WEBHOOK_URL ||
            'whsec_yji7EYiEPCgFXoWya1W1x7faJnZujNtA',
        },
      },
    },
  },
  webChangesSlackBotToken:
    process.env.WEB_CHANGES_SLACK_BOT_TOKEN ||
    'xoxb-14761716037-6310002727847-97wYH7VfdgJQKf5OXhfDR54u',
  craigslist: {
    postURL:
      process.env.CRAIGSLIST_POST_URL ||
      'https://post.craigslist.org/bulk-rss/post',
    validateURL:
      process.env.CRAIGSLIST_VALIDATE_URL ||
      'https://post.craigslist.org/bulk-rss/validate',
    apiUrl: process.env.CRAIGSLIST_AUTH_URL || 'https://bapi.craigslist.org/',
    accountId: process.env.CRAIGSLIST_ACCOUNT_ID || '78535',
    secret: process.env.CRAIGSLIST_SECRET || '8^CLNchPxT^!LjNS',
    email: process.env.CRAIGSLIST_ACCOUNT_EMAIL || 'product@turbotenant.com',
  },
  svix: {
    apiKey:
      process.env.SVIX_API_KEY || 'testsk_gYPNWQUmnBGPHteTfRlfZ31Po6PzloeH.us',
    redfinAppId:
      process.env.SVIX_REDFIN_APP_ID || 'app_2hQ6qadJddztzUryG3bfqzFAoBN',
  },
  fraudQueueHighRiskConstants: {
    bankNames: process.env.HIGH_RISK_BANK_NAMES?.split(',') || [],
  },
  thirdPartySwaggerDocs: {
    redfin: process.env.REDFIN_SWAGGER_PASSWORD || '6"Ls^8Zo}E7/',
    partners: process.env.PARTNERS_SWAGGER_PASSWORD || 'rCC3JF6yKOwLbaI',
  },
  swaggerDocs: {
    partners: process.env.PARTNER_SWAGGER_PASSWORD || 'FZxDRTmBtE9s36W',
    devs: process.env.DEVS_SWAGGER_PASSWORD || 'FkFZUHYen7AP0Gc',
  },
};
