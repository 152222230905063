import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../components/Spinner';

import styles from './IconButton.module.scss';

const IconButton = React.forwardRef(
  (
    {
      to,
      href,
      type = 'button',
      icon: Icon,
      iconProps = {},
      className,
      disabled,
      loading,
      ...otherProps
    },
    ref,
  ) => {
    if (loading) return <Spinner secondary />;

    const buttonProps = {
      className: cx(
        styles.button,
        {
          [styles.disabled]: disabled,
        },
        className,
      ),
      disabled,
      ref,
      ...otherProps,
    };

    // Render the appropriate element based on props
    if (to && !disabled) {
      return (
        <Link to={to} {...buttonProps}>
          <Icon {...iconProps} />
        </Link>
      );
    }

    if (href && !disabled) {
      return (
        <a href={href} {...buttonProps}>
          <Icon {...iconProps} />
        </a>
      );
    }

    return (
      <button type={type} {...buttonProps}>
        <Icon {...iconProps} />
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default IconButton;
