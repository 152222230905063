import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Unlock = ({
  color = 'currentColor',
  width = 24,
  height = 25,
  ...rest
}) => (
  <svg viewBox="0 0 24 24" width={width} height={height} fill={color} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.068 7.602C17.068 4.784 14.799 2.5 12 2.5l-.214.004c-2.7.113-4.854 2.352-4.854 5.098H8.15c0-2.142 1.724-3.878 3.851-3.878l.176.004c2.046.093 3.675 1.792 3.675 3.874v2.245H8.15l-1.217-.001H5.108a.61.61 0 0 0-.608.613v11.429a.61.61 0 0 0 .608.612h13.784a.61.61 0 0 0 .608-.612V10.459a.61.61 0 0 0-.608-.612l-1.825-.001V7.602ZM5.716 21.276V11.07h12.568v10.205H5.716Zm7.298-6.327c0-.564-.454-1.02-1.014-1.02s-1.014.456-1.014 1.02c0 .333.16.63.405.816v2.041a.61.61 0 0 0 .609.612.61.61 0 0 0 .608-.612v-2.042c.247-.186.405-.482.405-.815Z"
    />
  </svg>
);

Unlock.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Unlock);
