import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const PreScreener = ({
  color = colors.primary,
  width = 24,
  height = 25,
  ...rest
}) => (
  <svg viewBox="0 0 24 25" width={width} height={height} fill={color} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.742 5.746a.6.6 0 0 1 .849 0l1.24 1.24-.848.848-.816-.816-4.603 4.603-.849-.848 5.027-5.027ZM4.35 11.591a.6.6 0 0 1 .848 0l1.14 1.141-.848.849-.716-.717-1.426 1.425 3.7 3.7.485-.486.848.848-.91.91a.6.6 0 0 1-.848 0l-4.547-4.547a.6.6 0 0 1 0-.849l2.273-2.274Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.226 5.57a.6.6 0 0 1 .424.176l2.274 2.273a.6.6 0 0 1 0 .849L11.531 19.26a.6.6 0 0 1-.848 0l-4.548-4.547a.6.6 0 0 1 0-.85l2.274-2.273a.6.6 0 0 1 .848 0l1.85 1.85 7.695-7.695a.6.6 0 0 1 .424-.176Zm0 1.448-7.695 7.696a.6.6 0 0 1-.848 0l-1.85-1.85-1.425 1.425 3.699 3.7 9.544-9.545-1.425-1.426Z"
    />
  </svg>
);

PreScreener.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PreScreener);
