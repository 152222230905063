import React, { useEffect, useState } from 'react';
import LightBox from 'react-images';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import LulaUnpaidBanner from '../../../../components/Banners/LulaUnpaidBanner';
import Button from '../../../../components/Button';
import LoadingScreen from '../../../../components/LoadingScreen';
import LulaPromoCard from '../../../../components/LulaPromoCard/LulaPromoCard';
import LulaPromoCardB from '../../../../components/LulaPromoCardB';
import LulaWaitlistBannerWrapped from '../../../../components/LulaWaitlistBanner';
import Activity from '../../../../components/Maintenance/Activity';
import LulaOnboardingSuccessModal from '../../../../components/Maintenance/LulaOnboardingSuccessModal';
import MaintenanceResolved from '../../../../components/Maintenance/MaintenanceResolved';
import QuoteApprovalRequired from '../../../../components/Maintenance/QuoteApprovalRequired';
import SendWorkOrderCard from '../../../../components/Maintenance/SendWorkOrderCard';
import Modal from '../../../../components/Modal';
import RenderIfLulaEligible from '../../../../components/RenderIfLulaEligible/RenderIfLulaEligible';
import { useErrorToast, useInfoToast } from '../../../../components/Toast';
import MaintenanceRequestStatusEnum from '../../../../constants/enums/MaintenanceRequestStatusEnum';
import UserRoleEnums from '../../../../constants/enums/UserRolesEnum';
import { Experiments } from '../../../../constants/experiments';
import { FEATURE_FLAGS } from '../../../../constants/feature_flags';
import * as AuthService from '../../../../core/auth/authService';
import { useUserProfile } from '../../../../core/TTgraphql';
import maintenanceRequestQuery from '../../../../graphql/queries/maintenanceRequestQuery.graphql';
import getExperimentVariant from '../../../../helpers/experiments';
import getFormattedMaintenanceActivity from '../../../../helpers/getFormattedMaintenanceActivity';
import showIntercom from '../../../../helpers/showIntercom';
import wasLastStatusUpdateManual from '../../../../helpers/wasLastStatusUpdateManual';
import useOptimisticMaintenanceRequestUpdate from '../../../../hooks/maintenance_request/useOptimisticMaintenanceRequestUpdate';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities/segment';
import maintenanceRequestNotifyTenants from '../../graphql/maintenanceRequestNotifyTenants.graphql';

import LulaContactCard from './LulaContactCard';
import MaintenanceRequestCard from './MaintenanceRequestCard';
import MaintenanceRequestHeader from './MaintenanceRequestHeader';
import SendToLulaCard from './SendToLulaCard';
import TenantDetailsCard from './TenantDetailsCard';

import styles from './MaintenanceRequest.module.scss';

const MaintenanceRequest = ({ className, match }) => {
  const userToken = AuthService.getCurrentToken();
  const requestId = get(match, 'params.maintenanceRequestId', '');
  const listingId = get(match, 'params.listingGlobalId', '');
  const history = useHistory();
  const location = useLocation();
  const { autoSend, cardLastFour, nteAmount } = location.state || {};

  const { user, loading } = useUserProfile({
    polling: false,
  });

  const isMaintenancePlus = user?.maintenance_plus_subscription_subscribed;
  const [isLulaEnabled] = useFeatureToggles(FEATURE_FLAGS.LULA_MAINTENANCE);
  const [showDialog, setShowDialog] = useState(false);
  const [previewedImage, setPreviewedImage] = useState(null);
  const errorToast = useErrorToast();
  const showInfoToast = useInfoToast();

  const [optimisticMRUpdate] = useOptimisticMaintenanceRequestUpdate();

  const [notifyTenants, { loading: loadingNotify }] = useMutation(
    maintenanceRequestNotifyTenants,
  );

  const { data: maintenanceRequestResponse, loading: loadingMaintenance } =
    useQuery(maintenanceRequestQuery, {
      variables: {
        id: requestId,
      },
    });

  const currentRequest = maintenanceRequestResponse?.node;
  const listing = currentRequest?.listing;
  const requester = currentRequest?.requester || {};
  const isRequester = requester.userRole === UserRoleEnums.OWNER;
  const tenant = currentRequest?.renter;
  const quotes = currentRequest?.partner_work_order?.pending_quotes;
  const { PRIVATE_BASE_PATH } = useConfig();

  const lastStatusUpdatedManually = wasLastStatusUpdateManual(currentRequest);

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );

  const mrLulaCardExperimentVariant = getExperimentVariant(
    user,
    'maintenance_request_lula_pro_card_aug_2024',
  );

  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  useEffect(() => {
    if (currentRequest?.status === MaintenanceRequestStatusEnum.NEW) {
      optimisticMRUpdate(currentRequest.id, {
        status: MaintenanceRequestStatusEnum.OPEN,
      });
    }
  }, [loadingMaintenance]);

  const handleLulaModalClose = () => {
    history.replace({ ...location, state: {} });
  };

  const onUpdateMaintenanceStatus = async (response) => {
    try {
      const {
        data: {
          updateMaintenanceRequest: {
            maintenance_request: modifiedMaintenanceRequest,
          },
        },
      } = response;

      if (!modifiedMaintenanceRequest.resolved_at) {
        return;
      }

      const rentersNotified = get(
        modifiedMaintenanceRequest,
        'renters_notified.renters',
        [],
      );
      const hasTenants = rentersNotified && rentersNotified.length > 0;

      !currentRequest.resolved_at &&
        (hasTenants
          ? setShowDialog(true)
          : showInfoToast('Marked as resolved'));
    } catch (error) {
      errorToast('Error has occurred', error);
    }
  };

  const toggleStarred = () =>
    optimisticMRUpdate(currentRequest.id, {
      starred: !currentRequest.starred,
    });

  const onNotifyClicked = async () => {
    segmentTracking('notify_tenants clicked', {
      location: 'Maintenance Resolved Modal',
    });

    try {
      await notifyTenants({
        variables: { maintenance_request_id: currentRequest.id },
      });
    } catch (e) {
      errorToast('Error notifying tenants', e);
      return;
    }

    setShowDialog(false);
    showInfoToast('Marked as resolved and tenants notified.');
  };

  const onSkipClicked = () => {
    segmentTracking('skip_notifying_them clicked', {
      location: 'Maintenance Resolved Modal',
    });

    setShowDialog(false);
    showInfoToast('Marked as resolved.');
  };

  const onLearnMoreClicked = () => {
    segmentTracking('lula_learn_more clicked', {
      location: 'Maintenance Request',
      experiment: `maintenance_request_lula_pro_card_aug_2024_${mrLulaCardExperimentVariant}`,
    });

    history.push(`${PRIVATE_BASE_PATH}maintenance/onboarding`, {
      redirectToMrId: currentRequest.id,
      redirectToListingId: listingId,
    });
  };

  const canRenderLightBox = typeof previewedImage === 'number';

  if (loadingMaintenance || loading) {
    return <LoadingScreen loading />;
  }

  return (
    <LoadingScreen loading={loadingNotify}>
      <div className={cx(styles.container, className)}>
        <Modal
          style={{ maxWidth: '384px', padding: '32px' }}
          open={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <MaintenanceResolved
            onNotifyClicked={onNotifyClicked}
            onSkipClicked={onSkipClicked}
          />
        </Modal>
        {canRenderLightBox && (
          <LightBox
            isOpen
            currentImage={previewedImage}
            images={currentRequest.maintenance_request_attachments.map(
              (src) => ({
                src: src.url,
              }),
            )}
            onClickPrev={() => setPreviewedImage(previewedImage - 1)}
            onClickNext={() => setPreviewedImage(previewedImage + 1)}
            onClose={() => setPreviewedImage(null)}
          />
        )}
        <BackButton
          className={styles.back}
          to="/owners/maintenance/maintenance-requests"
        />
        {!isMaintenancePlus && !isEnrolledFreeLula && (
          <LulaWaitlistBannerWrapped
            bannerClassName={styles.banner}
            bannerLocation="Maintenance Request Page"
          />
        )}

        <MaintenanceRequestHeader
          className={styles.header}
          title={currentRequest.title}
          resolvedAt={currentRequest.resolved_at}
          listingId={listingId}
          listing={listing}
          maintenanceRequestId={currentRequest.id}
          userToken={userToken}
          status={currentRequest.status}
          lastStatusUpdatedManually={lastStatusUpdatedManually}
          starred={currentRequest.starred}
          onStatusChange={async (s) => {
            const response = await optimisticMRUpdate(currentRequest.id, {
              status: s,
            });
            await onUpdateMaintenanceStatus(response);
          }}
          toggleStarred={toggleStarred}
          isOwner={true}
          sentToPartner={currentRequest.sent_to_partner}
        />

        {!!user?.unpaid_maintenance_invoices?.length && (
          <LulaUnpaidBanner
            className={styles.banner}
            bannerButton={() => (
              <Button
                secondary
                className={styles.btn}
                href={`${PRIVATE_BASE_PATH}settings/billing`}
              >
                update billing
              </Button>
            )}
          >
            <p className={styles.text}>
              Update your billing method or retry the payment in order to
              continue sending requests to Lula. No requests can be sent to Lula
              until the payment is processed.{' '}
              <a
                className={styles.intercomButton}
                onClick={() => showIntercom()}
              >
                Chat with Turbotenant support
              </a>{' '}
              or reach out to{' '}
              <a
                className={styles.bannerLink}
                href="mailto:support@turbotenant.com"
              >
                support@turbotenant.com
              </a>{' '}
              if you have questions.{' '}
            </p>
          </LulaUnpaidBanner>
        )}

        {quotes?.map((quote) => {
          const expiresAt = get(quote, 'expiresAt', '');
          const quoteUrl = get(quote, 'uri', '');

          return (
            <QuoteApprovalRequired
              key={currentRequest.id}
              needApproval={currentRequest.title}
              expiresAt={expiresAt}
              quoteUrl={quoteUrl}
              nteAmount={get(
                user,
                'maintenance_request_management_settings.not_to_exceed',
                0,
              )}
            />
          );
        })}

        <div className={cx(styles.contentHolder)}>
          <div className={cx(styles.maintenanceContent)}>
            <MaintenanceRequestCard
              isOwner
              requestId={requestId}
              listingId={listingId}
              isRequester={isRequester}
              requestedOn={currentRequest.created_at}
              preferredTime={currentRequest.preferred_time}
              description={currentRequest.description}
              category={currentRequest.category}
              photos={currentRequest.maintenance_request_attachments}
              setPreviewedImage={setPreviewedImage}
              sentToPartner={currentRequest.sent_to_partner}
            />
            {tenant ? (
              <TenantDetailsCard
                className={styles.tenantCard}
                tenantId={tenant.id}
                fullName={`${tenant.first_name} ${tenant.last_name}`}
                lastActive={tenant.last_active}
                invitedToPortal={tenant.invited_to_portal}
                email={tenant.email}
                telephone={tenant?.telephone ? tenant.telephone : null}
              />
            ) : null}
          </div>

          <div className={cx(styles.actionCards)}>
            <RenderIfLulaEligible listing={listing}>
              {mrLulaCardExperimentVariant ===
              Experiments.MaintenanceRequestLulaProCardAug2024.variants
                .control ? (
                <LulaPromoCard
                  className={styles.lulaPromoCard}
                  onClick={onLearnMoreClicked}
                />
              ) : (
                <LulaPromoCardB
                  className={styles.lulaPromoCard}
                  onClick={onLearnMoreClicked}
                  city={listing.city}
                />
              )}
            </RenderIfLulaEligible>
            {(isMaintenancePlus || isEnrolledFreeLula) &&
              isLulaEnabled() &&
              !currentRequest?.sent_to_partner &&
              currentRequest?.status !==
                MaintenanceRequestStatusEnum.RESOLVED && (
                <SendToLulaCard
                  className={styles.sendToLula}
                  isAddressEligible={currentRequest?.listing?.lula_eligible}
                  maintenanceRequestId={currentRequest.id}
                  maintenancePlusSettings={maintenancePlusSettings}
                  disabled={!!user?.unpaid_maintenance_invoices.length}
                />
              )}
            <Activity
              activity={getFormattedMaintenanceActivity({
                activity: currentRequest.maintenance_activities,
                creationDate: currentRequest.created_at,
                isRequester,
                requester,
                attachments: currentRequest.maintenance_request_attachments,
                userId: user.id,
                listingTZ: listing.zip_tz,
              })}
              listingId={listing.id}
              maintenanceRequestId={currentRequest.id}
              sentToPartner={currentRequest.sent_to_partner}
              className={styles.activity}
            />
            {!currentRequest.sent_to_partner && (
              <div className={styles.desktop} data-qa="desktop-work-order-card">
                <SendWorkOrderCard
                  className={styles.cardStyle}
                  listingId={listingId}
                  maintenanceReqId={requestId}
                />
              </div>
            )}
            {currentRequest.sent_to_partner && (
              <div className={cx(styles.desktop, styles.lulaContact)}>
                <LulaContactCard className={styles.lulaContactCard} />
              </div>
            )}
          </div>

          {!currentRequest.sent_to_partner && (
            <div className={cx(styles.mobile, styles.mobileWorkOrder)}>
              <SendWorkOrderCard
                className={styles.cardStyle}
                listingId={listingId}
                maintenanceReqId={requestId}
              />
            </div>
          )}
          {currentRequest.sent_to_partner && (
            <div className={cx(styles.mobile, styles.lulaContact)}>
              <LulaContactCard />
            </div>
          )}
        </div>
      </div>
      {nteAmount ? (
        <LulaOnboardingSuccessModal
          open={true}
          onClose={handleLulaModalClose}
          onSoundGoodClick={handleLulaModalClose}
          nteAmount={nteAmount}
          autoSend={autoSend}
          cardLastFour={cardLastFour}
        />
      ) : null}
    </LoadingScreen>
  );
};

MaintenanceRequest.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
};

export default withRouter(MaintenanceRequest);
